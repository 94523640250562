<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center">
      <h6>
        {{ `${$t('fields.result')} (${requests.length})` }}
      </h6>
    </div>
    <!-- Table -->
    <b-table
      :items="requests"
      :fields="pendingFields"
      :busy="isFetching"
      responsive
      show-empty
    >
      <template #cell(#)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(created_at)="data">
        {{ data.value | datetime }}
      </template>
      <template #cell(detail)="data">
        <span v-if="data.value" class="text-danger"> {{ data.value }}</span>
        <span v-else>-</span>
      </template>
      <template #cell(bank_code)="data">
        {{ $displayBankName(data.value) }}
      </template>
      <template #cell(username)="data">
        <b-link
          :to="
            data.item.user_id
              ? `/players/detail/${data.item.user_id}`
              : `/players/list`
          "
        >
          {{ `${data.value}` }}
        </b-link>
      </template>
      <template #cell(amount)="data">
        <span class="text-warning">
          {{ data.value | currency }}
        </span>
      </template>
      <template #cell(operation)="data">
        <b-button-group size="sm">
          <b-button
            variant="success"
            @click="onApprovePendingCredit(data.item)"
          >
            {{ $t('utils.status.approve') }}
          </b-button>
          <b-dropdown variant="danger" size="sm" dropleft>
            <template #button-content>
              {{ $t('utils.status.reject') }} <i class="uil uil-angle-down"></i>
            </template>
            <b-dropdown-item
              variant="warning"
              @click="onRejectPendingCredit(data.item.id, true)"
            >
              <i class="uil uil-history mx-1"></i>
              {{ $t('utils.status.reject_return') }}
            </b-dropdown-item>
            <b-dropdown-item
              variant="danger"
              @click="onRejectPendingCredit(data.item.id, false)"
            >
              <i class="uil uil-multiply mx-1"></i>
              {{ $t('utils.status.reject_not_return') }}
            </b-dropdown-item>
          </b-dropdown>
          <b-button variant="warning" @click="onManualSetStatus(data.item)">
            ซ่อนรายการ
          </b-button>
        </b-button-group>
      </template>
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template #empty="">
        <p class="text-center text-muted">{{ $t('messages.nothing_here') }}</p>
      </template>
    </b-table>
    <!-- pagination -->
    <PaginationInput :per-page="limit" :total="totalRow" @update="(val) => currentPage = val"/>
    <b-modal
      v-model="isRejectModalActive"
      :title="`${$t('utils.status.reject')} - ${$t(
        'routes.VIEW_TRANSACTION_REQUEST'
      )}`"
      hide-footer
    >
      <ValidationObserver ref="rejectForm" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmitReject)">
          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('fields.remark')"
            :rules="!rejectForm.isReturnCredit ? 'required' : null"
          >
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="3"
              :label="`${$t('fields.remark')} ${
                !rejectForm.isReturnCredit ? '*' : ''
              }`"
              label-for="detail"
            >
              <b-form-input
                id="detail"
                v-model="rejectForm.detail"
                :state="errors[0] ? false : null"
              ></b-form-input>
            </b-form-group>
          </ValidationProvider>
          <div class="text-right">
            <b-button variant="light" class="mr-2" @click="onCloseModal">
              {{ $t('buttons.cancel') }}
            </b-button>
            <b-overlay
              :show="isRejecting"
              rounded
              spinner-small
              class="d-inline-block"
            >
              <b-button type="submit" variant="primary" block>
                {{ $t('buttons.confirm') }}
              </b-button>
            </b-overlay>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>

    <approve-withdraw-modal :txn="selectedTxn"></approve-withdraw-modal>
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  page: {
    title: 'Withdraw Request Transactions',
  },
  components: {
    ApproveWithdrawModal: () =>
      import('../../../components/transactions/approve-withdraw-modal.vue'),
  },
  data() {
    return {
      isRejectModalActive: false,
      currentPage: 1,
      limit: 50,
      offset: 0,
      selectedTxn: {},
      pendingFields: [
        '#',
        {
          key: 'created_at',
          label: `${this.$t('fields.issued_date')}`,
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'detail',
          label: `${this.$t('fields.remark')}`,
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'bank_code',
          label: this.$t('bank_accounts.bank'),
          thStyle: {
            minWidth: '250px',
          },
        },
        {
          key: 'bank_number',
          label: this.$t('bank_accounts.account_no'),
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'name',
          label: this.$t('bank_accounts.name'),
          thStyle: {
            minWidth: '170px',
          },
        },
        {
          key: 'amount',
          label: this.$t('credits.credit_amount'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'username',
          label: this.$t('credits.receiver'),
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'operation',
          label: `${this.$t('buttons.operation')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
      ],
      rejectForm: { txnId: '', detail: '', isReturnCredit: false },
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.credit.isFetchingWithdrawRequests,
      isRejecting: (state) => state.credit.isRejectingPlayerWithdraw,
      isRejectingSuccess: (state) =>
        state.credit.isRejectingPlayerWithdrawSuccess,
    }),
    ...mapGetters(['withdrawRequests']),
    requests() {
      return this.withdrawRequests.data || []
    },
    totalRow() {
      return this.withdrawRequests.total || 0
    },
  },
  watch: {
    currentPage(val) {
      const nextPage = val - 1
      const nextVal = nextPage * this.limit
      this.offset = nextVal
      this.fetchData()
    },
    isRejectingSuccess(val) {
      if (val) {
        this.onCloseRejectModal()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions([
      'fetchWithdrawRequests',
      'rejectPlayerWithdraw',
      'acceptPlayerWithdraw',
      'manualSetStatusToSuccess',
    ]),
    fetchData() {
      // this.fetchWithdrawRequests({
      //   limit: this.limit,
      //   offset: this.offset,
      // })
    },
    onApprovePendingCredit(txn) {
      this.$bvModal.show('approve-withdraw-modal')
      this.selectedTxn = txn
      // this.$bvModal
      //   .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
      //     okTitle: `${this.$t('buttons.confirm')}`,
      //     cancelTitle: `${this.$t('buttons.cancel')}`,
      //   })
      //   .then((value) => {
      //     if (value) {
      //       this.acceptPlayerWithdraw(txnId)
      //     }
      //   })
    },
    onRejectPendingCredit(txnId, isReturnCredit) {
      this.rejectForm.txnId = txnId
      this.rejectForm.isReturnCredit = isReturnCredit
      this.isRejectModalActive = !this.isRejectModalActive
    },
    onCloseRejectModal() {
      this.isRejectModalActive = false
      this.rejectForm = {
        txnId: '',
        detail: '',
        isReturnCredit: false,
      }
    },
    onSubmitReject() {
      this.rejectPlayerWithdraw(this.rejectForm)
    },
    onManualSetStatus(txn) {
      this.manualSetStatusToSuccess({ txnId: txn.id })
    },
  },
}
</script>
